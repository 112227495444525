<template>
  <vue-final-modal
    v-bind="$attrs"
    :ssr="false"
    :name="nameModal"
    :overlay-style="overlayStyle"
    classes="modal-container-small"
    content-class="modal-mobile"
    v-on="$listeners"
    @before-open="handlerBeforeOpen"
    @before-close="handlerBeforeClose"
  >
    <div class="modal__content">
      <slot></slot>
    </div>
  </vue-final-modal>
</template>

<script>
import { computed, defineComponent, useStore } from '@nuxtjs/composition-api';
import useModalHandlers from '@/compositions/modal/useModalHandlers';

export default defineComponent({
  name: 'InstallModal',
  props: {
    nameModal: {
      type: String,
      default: 'AppModal',
    },
  },
  setup() {
    const {
      handlerBeforeOpen,
      handlerBeforeClose,
    } = useModalHandlers();
    const store = useStore();

    const overlayStyle = {
      background: 'rgb(0, 0, 0, 0.2)',
    };

    const isAuthorized = computed({
      get: () => store.state.isAuthorized,
      set: () => {},
    });

    return {
      handlerBeforeOpen,
      handlerBeforeClose,
      overlayStyle,
      isAuthorized,
    };
  },
  data: () => ({
    deferredPrompt: null,
    isLoading: false,
    isSuccessful: false,
  }),
  computed: {
    textButtonAdd() {
      return this.isAuthorized ? 'Добавить' : 'Войти и добавить';
    },
  },
});
</script>

<style lang="less">
.modal-container-small {
  margin: auto;
  background-color: transparent;
  padding: @size-x6;
  text-align: center;
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-mobile {
    background: none;
    width: auto;
    height: auto;
    display: block;
  }
}
</style>
